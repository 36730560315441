





























































































































































import { defineComponent, onMounted, reactive, ref } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

interface StepError {
  step: number;
  error: string;
}

export default defineComponent({

  setup(_, { root }) {
    const search = ref<HTMLInputElement | null>(null)

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      dialog: false
    })

    const model = reactive<{
      data: any;
    }>({
      data: {}
    })

    //  Start fetch single paymentCredential data

    const fetchData = () => {
      const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

      state.loading = true

      axiosInstance
        .get(`payment-credentials/${root.$route.params.id}`)
        .then(({ data: { paymentCredentials } }) => {
          model.data.name = paymentCredentials.name
          model.data.type = paymentCredentials.type || undefined
          model.data.currency = paymentCredentials.currency || undefined
          model.data.clientId = paymentCredentials.clientId || null
          model.data.clientSecret = paymentCredentials.clientSecret || null
          model.data.merchantPosId = paymentCredentials.merchantPosId || null
          model.data.secondKey = paymentCredentials.secondKey || null
          model.data.accountOwner = paymentCredentials.accountOwner || null
          model.data.merchantPosId = paymentCredentials.merchantPosId || null
          model.data.bankName = paymentCredentials.bankName || null
          model.data.accountNumber = paymentCredentials.accountNumber || null
          model.data.isActive = paymentCredentials.isActive
        })
        .catch((error) => console.log(error))
        .finally(() => state.loading = false)
    }

    onMounted(fetchData)

    //  End fetch single paymentCredential data

    // Start update item

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

      const data = {
        name: model.data.name,
        type: model.data.type || undefined,
        currency: model.data.currency || undefined,
        clientId: model.data.clientId || null,
        clientSecret: model.data.clientSecret || null,
        merchantPosId: model.data.merchantPosId || null,
        secondKey: model.data.secondKey || null,
        accountOwner: model.data.accountOwner || null,
        bankName: model.data.bankName || null,
        accountNumber: model.data.accountNumber || null,
        isActive: model.data.isActive
      }

      state.loading = true

      axiosInstance
        .put(`payment-credentials/${root.$route.params.id}`, data)
        .then(() => {
          state.dialog = true
          state.success = true
        })
        .catch(error => {
          state.error = error.response.status
          state.dialog = true
        })
        .finally(() => state.loading = false)
    }

    // End update item

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403: return 'Brak uprawnień'
        case 404: return 'Endpointu nie znaleziono'
        case 409: return 'Metoda już istnieje'
        case 500: return `${root.$tc('layout.errors.500')}`
        default: return 'Wystąpił bład'
      }
    }

    const rules: {
      [key: string]: (v: any) => boolean | string;
    } = {
      required: v => !!v || `${root.$t('layout.misc.required')}`
    }

    return {
      state,
      model,
      onSubmit,
      getErrorMessage,
      fetchData,
      search,
      rules
    }
  }
})
